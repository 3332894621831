<template>
<b-form class="form" @submit.stop.prevent="onSubmit">
  <b-container fluid class="p-0">
    <b-row>
      <b-col cols="12">
        <b-form-group :label="`${$t('FORM.COST')}`" label-for="input-1">
          <InputCurrency
            id="input-1"
            v-model="$v.form.cost.$model"
            :state="validateState('cost')"
            :placeholder="$t('PLACEHOLDER.COST')"
            trim
            @input="clearServerError('cost')"
            aria-describedby="input-1-feedback"
          />
          <b-form-invalid-feedback id="input-1-feedback">
            <template v-if="serverErrors.cost">{{ serverErrors.cost[0] }}</template>
            <template v-else>{{ $t('VALIDATION.REQUIRED', { name: $t('FORM.COST') }) }}</template>
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group :label="`${$t('FORM.START_DATE')}`" label-for="datepicker">
          <Datepicker id="datepicker"
                      v-model="$v.form.startDate.$model"
                      :state="validateState('startDate')"
                      @input="clearServerError('startDate');clearServerError('endDate')"
                      aria-describedby="datepicker-feedback"
          />
          <b-form-invalid-feedback id="datepicker-feedback">
            <template v-if="serverErrors.startDate">{{ serverErrors.startDate[0] }}</template>
            <template v-else>{{ $t('VALIDATION.REQUIRED', { name: $t('FORM.START_DATE') }) }}</template>
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group :label="`${$t('FORM.END_DATE')}`" label-for="datepicker-2">
          <Datepicker id="datepicker-2"
                      v-model="$v.form.endDate.$model"
                      :state="validateState('endDate')"
                      :disabled="!form.startDate"
                      @input="clearServerError('endDate');clearServerError('startDate')"
                      aria-describedby="datepicker-2-feedback"
          />
          <b-form-invalid-feedback id="datepicker-2-feedback">
            <template v-if="serverErrors.endDate">{{ serverErrors.endDate[0] }}</template>
            <template v-else>{{ $t('VALIDATION.REQUIRED', { name: $t('FORM.START_DATE') }) }}</template>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <div class="text-right">
          <b-button :disabled="loading || $v.form.$anyError" type="submit" variant="primary" size="sm">
            <b-spinner v-if="loading" small variant="light" />
            {{ $t('FORM.SUBMIT') }}
          </b-button>
        </div>
      </b-col>
    </b-row>
  </b-container>
</b-form>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import Repo from '@/core/repository/company/productsPricesRepository'
import serverVuelidate from '../../../mixins/serverVuelidate'
import Datepicker from '../../forms-items/Datepicker'
import InputCurrency from '../../forms-items/inputCurrency'

export default {
  name: 'ProductPriceForm',
  components: {
    InputCurrency,
    Datepicker,
  },
  mixins: [
    validationMixin,
    serverVuelidate,
  ],
  props: {
    product: {
      type: String,
      default: '',
    },
    itemEdit: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loading: false,
      form: {
        uuid: '',
        cost: '',
        startDate: '',
        endDate: '',
      },
      searchProduct: '',
      validations: {
        form: {
          uuid: {},
          cost: { required },
          startDate: { required },
          endDate: { required },
        },
      },
    }
  },
  created() {
    console.log('itemEdit', this.itemEdit)
    if (this.itemEdit && Object.keys(this.itemEdit).length) {
      this.setItem(this.itemEdit)
    }
  },
  methods: {
    setItem(item) {
      Object
        .keys(this.form)
        .forEach((propName) => {
          if (this.form.hasOwnProperty(propName) && item.hasOwnProperty(propName)) {
            if (item[propName] !== null && item[propName] !== undefined) {
              this.form[propName] = item[propName]
            }
          }
        })
    },
    resetForm() {
      this.form = {
        uuid: {},
        cost: '',
        startDate: null,
        endDate: null,
      }
      this.$nextTick(() => {
        this.$v.$reset()
      })
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name]
      return $dirty ? !$error : null
    },
    onSubmit() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        return
      }
      this.clearServerErrors()

      this.loading = true

      const obj = { ...this.form }
      obj.product = this.product
      const { uuid, ...rest } = obj

      if (uuid) {
        Repo
          .patch(uuid, rest)
          .then(() => {
            this.resetForm()
            this.$emit('onEdit', obj)
          })
          .catch((err) => {
            this.seServerErrors(err.response.data)
          })
          .finally(() => this.loading = false)
      } else {
        Repo
          .post(rest)
          .then(({ data }) => {
            this.resetForm()
            this.$emit('onAdd', {
              ...obj,
              uuid: data.payload.uuid,
            })
          })
          .catch((err) => {
            this.seServerErrors(err.response.data)
          })
          .finally(() => this.loading = false)
      }
    },
  },
}
</script>
